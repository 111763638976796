export default {
  data () {
    return {
      scrolling: false
    }
  },

  mounted () {
    window.addEventListener('scroll', this.scrollListenerFunction)
  },

  beforeUnmount () {
    window.removeEventListener('scroll', this.scrollListenerFunction)
  },

  methods: {
    scrollListenerFunction (e) {
      if (window.scrollY > 0 && window.innerWidth <= 768) {
        this.scrolling = true
      } else {
        this.scrolling = false
      }
    }
  }
}
